import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form } from "../../components/UI/Form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { FormInput } from "../../components/UI/Form/FormTextInput";
import { Button } from "../../components/UI/Button";
import PagesHeader from "../../components/PagesHeader/PagesHeader";
import { userChangePassword } from "../../services/user.service";
import { useNotificationContext } from "../../contexts/Notification-Context";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFnRequest } from "../../hooks/useFnRequest/useFnRequest";
import { PASSWORD_VALIDATOR } from "../../utils/validators";

const changePasswordSchema = z
  .object({
    currentPassword: z.string().min(1, "A senha atual é obrigatória"),
    newPassword: z
      .string()
      .min(1, "A nova senha é obrigatória")
      .regex(
        PASSWORD_VALIDATOR,
        "A senha precisa ter pelo menos 4 caracteres, sendo pelo menos 1 letra maiúscula, 1 número e 1 caractere especial"
      ),
    confirmNewPassword: z
      .string()
      .min(1, "A confirmação da nova senha é obrigatória"),
  })
  .superRefine((val, ctx) => {
    if (val.newPassword !== val.confirmNewPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["newPassword"],
        message: "A nova senha e a confirmação de senha não coincidem",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["confirmNewPassword"],
        message: "A nova senha e a confirmação de senha não coincidem",
      });
    }
  });

export type TChangePassword = z.infer<typeof changePasswordSchema>;

const ChangePasswordPage = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const { setMessage } = useNotificationContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const { sendRequest, loading } = useFnRequest(userChangePassword);

  const methods = useForm<TChangePassword>({
    resolver: zodResolver(changePasswordSchema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = useCallback(
    async (values: TChangePassword) => {
      const { data, success } = await sendRequest({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword
      });
      if (data && success) {
        setMessage({
          message: "Senha alterada com sucesso",
          notificationKey: new Date().getMilliseconds(),
          type: "success",
        });
        reset();
      }
    },
    [reset, setMessage, sendRequest]
  );

  return (
    <Box>
      <PagesHeader title="Alteração de senha" />
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 10,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Stack alignItems={"center"}>
            <Stack sx={{ width: "80%" }} mt={5}>
              <Grid
                container
                spacing={2}
                sx={{ width: matches ? "100%" : "60%" }}
              >
                <Grid item xs={12} sm={12}>
                  <FormInput
                    name="currentPassword"
                    label="Senha atual"
                    size="small"
                    fullWidth
                    type={showCurrentPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowCurrentPassword(
                                (oldState) => (oldState = !oldState)
                              )
                            }
                            edge="end"
                          >
                            {!showCurrentPassword && <RemoveRedEyeIcon />}
                            {showCurrentPassword && <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    name="newPassword"
                    label="Nova senha"
                    size="small"
                    fullWidth
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowNewPassword(
                                (oldState) => (oldState = !oldState)
                              )
                            }
                            edge="end"
                          >
                            {!showNewPassword && <RemoveRedEyeIcon />}
                            {showNewPassword && <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInput
                    name="confirmNewPassword"
                    label="Confirmar nova senha"
                    size="small"
                    fullWidth
                    type={showNewPassword ? "text" : "password"}
                  />
                </Grid>
                <Grid item sm={12} mt={5}>
                  <Button loading={loading} variant="contained" type="submit">
                    Alterar senha
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Form>
    </Box>
  );
};

export default ChangePasswordPage;
