import { Box, Stack, Typography } from "@mui/material";

import { Button } from "../../UI/Button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form } from "../../UI/Form";
import { FormInput } from "../../UI/Form/FormTextInput";
import { useNavigate } from "react-router-dom";

const forgotPasswordSchema = z.object({
    mail: z.string().min(1, "O login é obrigatório"),
});

export type TForgotPassword = z.infer<typeof forgotPasswordSchema>;

const ForgetPasswordForm = ({
  loading,
  onSubmit,
}: {
  loading: boolean;
  onSubmit: (values: TForgotPassword) => Promise<void>;
}) => {
  const navigate = useNavigate();
  const methods = useForm<TForgotPassword>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const { handleSubmit } = methods;

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: 10,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack textAlign={"center"} mb={4}>
          <Typography variant="h6" mb={1}>
            Esqueceu a senha?
          </Typography>
          <Typography variant="subtitle2">
            Insira seu login abaixo, e enviaremos um código para o seu e-mail
            para realizar a alteração da senha
          </Typography>
        </Stack>

        <FormInput sx={{ mb: 2 }} name="mail" label="Login" size="small" />

        <Button
          loading={loading}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
        >
          Enviar
        </Button>
        <Button
          fullWidth
          type="submit"
          variant="outlined"
          color="primary"
          onClick={() => navigate("/login")}
          sx={{ mt: 2 }}
        >
          Voltar para o login
        </Button>
      </Box>
    </Form>
  );
};

export default ForgetPasswordForm;
