import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { authService } from "../services/auth.service";
import { useCallback } from "react";
import { useFnRequest } from "../hooks/useFnRequest/useFnRequest";
import { useParams } from "react-router-dom";
import ResetPasswordForm, {
  TResetPasswordForm,
} from "../components/Auth/ResetPasswordForm/ResetPasswordForm";
import { useNotificationContext } from "../contexts/Notification-Context";

const ResetPasswordPage = () => {
  const { sendRequest, loading } = useFnRequest(authService.resetPassword);
  const { token } = useParams();
  const { setMessage } = useNotificationContext();

  const onSubmitHandler = useCallback(
    async (values: TResetPasswordForm) => {
      if (token) {
        const { success } = await sendRequest({
          password: values.password,
          passwordResetToken: token,
        });
        if (success) {
          setMessage({
            message: "Senha alterada com sucesso!",
            notificationKey: new Date().getMilliseconds(),
            type: "success",
          });
          window.location.href = 'https://www.dimdimapp.com.br/atualizar-senha';
        }
      }
    },
    [sendRequest, token, setMessage]
  );

  return (
    <Box
      sx={(theme) => ({
        height: "100vh",
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={(theme) => ({
            p: 5,
            width: 1,
            maxWidth: 380,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <img
            style={{ width: 300 }}
            src="/dimdim_logo.png"
            alt="Logo DimDim"
          />
          <ResetPasswordForm loading={loading} onSubmit={onSubmitHandler} />
        </Card>
      </Stack>
    </Box>
  );
};

export default ResetPasswordPage;
